<template>
  <interview-layout :touch-and-validate="touchAndValidate">
    <!-- inner wrapper used for centering -->
    <div class="">
      <!-- title above form card -->
      <h1 class="px-4 pb-3 fade-in-up-enter-active animation-slowest max-100 text-primary">Subsidieregelingen</h1>
      <h5 class="px-4 pb-3 fade-in-up-enter-active animation-slowest delay-200 max-100">Inkomensafhankelijke regelingen</h5>
      <div class="d-flex flex-column flex-lg-row justify-content-center">

        <!-- form 1 card -->
        <base-card inner-class="container-narrow w-100" color="alternate" :outer-padding="true">
          <base-form @submit="onSubmit">

            <div>
              <h6 class="pb-3 mt-3 max-100">Subsidies voor lagere inkomens</h6>
              <div class="small mb-4">
                  <checkmark-item class="mb-2" icon="info-circle-fill">
                    Gemeentelijke subsidies en maatregelen uit <a href="https://www.nijbegun.nl/">Nij Begun</a> zijn soms inkomensafhankelijk.
                  </checkmark-item>
                  <checkmark-item class="mb-2" icon="info-circle-fill">
                    Met deze vragen maken wij een inschatting of uw inkomen binnen de grenzen valt.
                  </checkmark-item>
                  <checkmark-item class="mb-2" icon="people-fill">
                    Hierbij wordt het inkomen <strong class="fw-600">per huishouden</strong> berekend.
                  </checkmark-item>
                  <checkmark-item class="mb-2" icon="eye-slash-fill">
                    <strong class="fw-600">Uw precieze inkomen wordt niet verstuurd.</strong> Wij bewaren alleen aan welke inkomenseisen u voldoet, bijvoorbeeld "tot 140% van het sociaal minimum."
                  </checkmark-item>
              </div>
            </div>

            <base-input
              class="mt-5"
              type="radiobuttons" :options="yesNoDontKnow"
              name="ReceivedEnergySupport"
              v-model:value="lead.financing.income.ReceivedEnergySupport" :validator="v$.financing.income.ReceivedEnergySupport">
              Heeft u energietoeslag ontvangen?
            </base-input>
            
            <base-card class="mt-5" v-show="showIncomeQuestions">
              <div class="d-sm-flex gap-5 mb-0 align-items-center">
                <base-input
                  type="number"
                  step="1"
                  name="incomeStore.income"
                  class="mt-0"
                  :dim-label-on-value="false"
                  v-model:value="incomeStore.nettIncome">
                  <template #before>
                    <span class="unit px-2 fs-0-8em fw-600 text-secondary">&euro;</span>
                  </template>
                  <template #default>
                    Wat is uw netto maandinkomen?
                  </template>
                  <template #after>
                    <span class="unit px-2 fs-0-8em fw-600 text-secondary d-block" style="text-wrap: nowrap;">p/maand</span>
                  </template>
                </base-input>
  <!-- 
                <base-input
                  v-show="showIncomeQuestions"
                  type="radiobuttons" :options="incomePeriods"
                  name="incomeStore.incomePeriod"
                  v-model:value="incomeStore.incomePeriod">
                  Inkomen per
                </base-input> -->
              </div>
              
              <div class="fs-0-8em mt-2">
                <checkmark-item class="mb-2" icon="info-circle-fill" icon-color="secondary">
                  Het gaat om uw <strong class="fw-600">netto</strong> maandloon, <strong class="fw-600">exclusief vakantiegeld</strong>. Dit bedrag vindt u op uw loonstrook achter "totaal nettoloon".
                </checkmark-item>
                <checkmark-item class="mb-2" icon="info-circle-fill" icon-color="secondary">
                  Heeft u een partner? Tel dan de netto bedragen van beide loonstroken bij elkaar op.
                </checkmark-item>
                <checkmark-item class="mb-2" icon="eye-slash-fill" icon-color="secondary">
                  Dit getal wordt alleen in uw browser bewaard en niet verstuurd.
                </checkmark-item>
              </div>
            </base-card>

            <base-card inner-class="py-2 bg-transparent" v-show="showHouseholdQuestions" class="mt-5">
              <base-input
                type="radiobuttons" :options="civilStatusOptions"
                name="Consent"
                v-model:value="lead.financing.income.CivilStatus" :validator="v$.financing.income.CivilStatus">
                Bent u alleenstaand of gehuwd?
              </base-input>

              <base-input
                type="radiobuttons" :options="ageBrackets" text-align="left" direction="column"
                name="Consent"
                v-model:value="lead.financing.income.AgeBracket" :validator="v$.financing.income.AgeBracket">
                In welke leeftijdsgroep valt u?
              </base-input>
              
              <transition name="fade-in-up">
                <base-input
                  v-if="lead.financing.income.CivilStatus === 'Gehuwd of samenwonend'"
                  type="radiobuttons" :options="ageBrackets" text-align="left" direction="column"
                  name="Consent"
                  v-model:value="lead.financing.income.AgeBracketPartner" :validator="v$.financing.income.AgeBracketPartner">
                  In welke leeftijdsgroep valt uw partner?
                </base-input>
              </transition>

              <base-input
                type="radiobuttons" :options="yesNo"
                name="Consent"
                v-model:value="lead.financing.income.HasChildren" :validator="v$.financing.income.HasChildren">
                Heeft u thuiswonende kinderen?
              </base-input>
            </base-card>

            <base-card class="mt-5" inner-class="small" color="secondary" v-show="showIncomeCategories">
              <h6>Inkomenscategorie&euml;n:</h6>
              <i class="d-block mb-4 small">Dit zijn de gegevens die wij opslaan.</i>
              <checkmark-item
                v-show="showHouseholdQuestions && socialMinimum > 0"
                class="mb-2"
                icon="info-circle-fill"
                icon-color="info">
                Het sociaal minimum voor uw situatie is waarschijnlijk: <strong class="fw-600">{{ socialMinimum_pretty }}</strong> per maand.
              </checkmark-item>
              <checkmark-item v-show="lead.financing.income.ReceivedEnergySupport == 'Ja'" class="mb-2" icon="check-circle-fill">
                u kwam in aanmerking voor energietoeslag
              </checkmark-item>
              <checkmark-item v-show="SM_110pct" class="mb-2" icon="check-circle-fill">
                tot 110% van het sociaal minimum
              </checkmark-item>
              <checkmark-item v-show="SM_125pct" class="mb-2" icon="check-circle-fill">
                tot 125% van het sociaal minimum
              </checkmark-item>
              <checkmark-item v-show="SM_140pct" class="mb-2" icon="check-circle-fill">
                tot 140% van het sociaal minimum
              </checkmark-item>
              <!-- <checkmark-item v-show="WML_100pct" class="mb-2" icon="check-circle-fill">
                tot 100% van het wettelijk minimumloon obv. fulltime.
              </checkmark-item> -->
              <checkmark-item v-show="WML_125pct" class="mb-2 opacity-75" icon="check-circle-fill">
                tot 125% van het wettelijk minimumloon
              </checkmark-item>
              <checkmark-item v-show="WML_140pct" class="mb-2 opacity-75" icon="check-circle-fill">
                tot 140% van het wettelijk minimumloon
              </checkmark-item>
              <checkmark-item v-show="over_WML_140pct" class="mb-2 opacity-75" icon="check-circle-fill">
                meer dan 140% van het wettelijk minimumloon
              </checkmark-item>
            </base-card>

          </base-form>
        <!-- form card end -->
        </base-card>
      
      <!-- cards wrapper end -->
      </div>
      <button-row class="mt-2 flex-row-reverse">
        <base-button class="ms-auto" icon="bi-arrow-right" @click="onSubmit" :disabled="v$.financing.income.$invalid || incomeInvalid">Volgende</base-button>
        <base-button variant="minimal-secondary" icon="bi-arrow-left" icon-position="start" @click="onPrev">Terug</base-button>
      </button-row>
      <!-- inner wrapper end -->
    </div>
  </interview-layout>
</template>

<script>
  import InterviewLayout from "@/views/layouts/InterviewLayout.vue"
  import BaseCard from '@/components/BaseCard.vue'
  import BaseForm from "@/components/BaseForm.vue"
  import BaseInput from "@/components/BaseInput.vue"
  import BaseButton from "@/components/BaseButton.vue"
  import ButtonRow from "@/components/ButtonRow.vue"
  // import VariablePill from "@/components/VariablePill.vue"
  import CheckmarkItem from "@/components/CheckmarkItem.vue"
  // import IssueFeedback from "@/components/IssueFeedback.vue"

  import { useAllStores } from "@/helpers/UseAllStores"
  import { CommonComposable } from "@/helpers/CommonComposable"
  import { FlowService } from "@/services/FlowService"
  import { civilStatusOptions, ageBrackets, getSocialMinimum } from "@/services/SocialMinimumService"
  import { minimumMonthlyNettWages } from "@/helpers/MinimumWage"
    
  import { LeadDataService } from "@/services/LeadDataService"

  export default {
    components: {
      InterviewLayout,
      BaseCard,
      BaseForm,
      BaseInput,
      BaseButton,
      ButtonRow,
      // VariablePill,
      CheckmarkItem,
      // IssueFeedback
    },
    setup(props, context) {
      return { ...CommonComposable(props, context), ...useAllStores() };
    },
    data() {
      return {
        yesNo: [
          {
            label: 'Ja',
            value: true
          },
          {
            label: 'Nee',
            value: false
          },
        ],
        yesNoDontKnow: [
          {
            label: 'Ja',
            value: 'Ja'
          },
          {
            label: 'Nee',
            value: 'Nee'
          },
          {
            label: 'Weet/zeg ik niet',
            value: 'Weet ik niet'
          }
        ],
        moreLess: [
          {
            label: '- Lager',
            value: false
          },
          {
            label: 'Hoger +',
            value: true
          },
        ],
        incomePeriods: [
          {
            label: 'jaar',
            value: 'jaar'
          },
          {
            label: 'maand',
            value: 'maand'
          },
        ],
        civilStatusOptions: civilStatusOptions,
        ageBrackets: ageBrackets
      };
    },
    computed: {
      socialMinimum() {
        const nipFields = this.lead.financing.income;
        return getSocialMinimum(nipFields.AgeBracket, nipFields.AgeBracketPartner, nipFields.CivilStatus,  nipFields.HasChildren);
      },
      socialMinimum_pretty() {
        return new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 }).format(this.socialMinimum);
      },
      // socialMinimum125_pretty() {
      //   const socialMinimum125 = (this.socialMinimum * 1.25);
      //   return new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 }).format(socialMinimum125);
      // },
      // monthlyWage125Pct() {
      //   let maxWage = getMonthlyMinimumWage(2023, 2, 1.25, true, 0);
      //   return new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 }).format(maxWage);
      // },
      // annualWage125Pct() {
      //   let maxWage = getAnnualMinimumWage(2023, 1.25, true, 0);
      //   return new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 }).format(maxWage);
      // },
      showIncomeQuestions() {
        return this.lead.financing.income.ReceivedEnergySupport === 'Nee' || this.lead.financing.income.ReceivedEnergySupport === 'Weet ik niet';
      },
      incomeInvalid() {
        return this.showIncomeQuestions && this.incomeStore.nettIncome < 1;
      },
      showHouseholdQuestions() {
        if (this.showIncomeQuestions == false || this.incomeStore.nettIncome == null || this.incomeStore.nettIncome < 1) {
          return false;
        } else if (this.incomeStore.nettIncome > minimumMonthlyNettWages['2025'].h1.nett140pct) {
          return false; // over 140% of minimum wage
        } else {
          return true;
        }
      },
      showIncomeCategories() {
        if (this.lead.financing.income.ReceivedEnergySupport === 'Ja') {
          return true;
        } else {
          return this.lead.financing.income.ReceivedEnergySupport && this.incomeStore.nettIncome > 0;
        }
      },

      SM_100pct() {
        return this.incomeStore.nettIncome <= Math.round(getSocialMinimum(this.lead.financing.income.AgeBracket, this.lead.financing.income.AgeBracketPartner, this.lead.financing.income.CivilStatus, this.lead.financing.income.HasChildren));
        // return this.incomeStore.annualIncome > getAnnualMinimumWage(2023, 1.25, true, 0);
      },
      SM_110pct() {
        return this.incomeStore.nettIncome <= Math.round(getSocialMinimum(this.lead.financing.income.AgeBracket, this.lead.financing.income.AgeBracketPartner, this.lead.financing.income.CivilStatus, this.lead.financing.income.HasChildren) * 1.1);
        // return this.incomeStore.annualIncome > getAnnualMinimumWage(2023, 1.25, true, 0);
      },
      SM_125pct() {
        if (this.lead.financing.income.ReceivedEnergySupport === 'Ja') { return true; }
        return this.incomeStore.nettIncome <= Math.round(getSocialMinimum(this.lead.financing.income.AgeBracket, this.lead.financing.income.AgeBracketPartner, this.lead.financing.income.CivilStatus, this.lead.financing.income.HasChildren) * 1.25);
      },
      SM_140pct() {
        if (this.lead.financing.income.ReceivedEnergySupport === 'Ja') { return false; }
        return this.incomeStore.nettIncome <= Math.round(getSocialMinimum(this.lead.financing.income.AgeBracket, this.lead.financing.income.AgeBracketPartner, this.lead.financing.income.CivilStatus, this.lead.financing.income.HasChildren) * 1.4);
      },
      WML_100pct() {
        return this.incomeStore.nettIncome <= minimumMonthlyNettWages['2025'].h1.nett100pct;
      },
      WML_125pct() {
        if (this.lead.financing.income.ReceivedEnergySupport === 'Ja') { return true; }
        return this.incomeStore.nettIncome <= minimumMonthlyNettWages['2025'].h1.nett125pct;
      },
      WML_140pct() {
        if (this.lead.financing.income.ReceivedEnergySupport === 'Ja') { return false; }
        return this.incomeStore.nettIncome <= minimumMonthlyNettWages['2025'].h1.nett140pct;
      },
      over_WML_140pct() {
        if (this.lead.financing.income.ReceivedEnergySupport === 'Ja') { return false; }
        return this.incomeStore.nettIncome > minimumMonthlyNettWages['2025'].h1.nett140pct;
      }
    },
    methods: {
      touchAndValidate() {
        this.v$.ownership.$touch();
        return !this.v$.ownership.$invalid;
      },
      onSubmit() {
        if (this.touchAndValidate()) {
          FlowService.pushNext(this);
        }
      },
      onPrev() {
        FlowService.pushPrev(this);
      },

      pushToLeadStore() {
        if (!this.incomeStore.nettIncome && !this.lead.financing.income.ReceivedEnergySupport) {
          return; // don't save if no income or ReceivedEnergySupport is entered
        }
        this.lead.financing.income.CalculatedSocialMinimum = this.socialMinimum;
        this.lead.financing.income.AppliedNettMinimumWages = minimumMonthlyNettWages['2025'].h1;
        this.lead.financing.income.SM_100pct = this.SM_100pct;
        this.lead.financing.income.SM_110pct = this.SM_110pct;
        this.lead.financing.income.SM_125pct = this.SM_125pct;
        this.lead.financing.income.SM_140pct = this.SM_140pct;
        this.lead.financing.income.WML_100pct = this.WML_100pct;
        this.lead.financing.income.WML_125pct = this.WML_125pct;
        this.lead.financing.income.WML_140pct = this.WML_140pct;
        this.lead.financing.income.over_WML_140pct = this.over_WML_140pct;
      }
    },
    watch: {
      socialMinimum() {
        this.pushToLeadStore();
      },
      'lead.financing.income.ReceivedEnergySupport'() {
        this.pushToLeadStore();
      },
      'incomeStore.nettIncome'() {
        this.pushToLeadStore();
      },
      showHouseholdQuestions() {
        this.lead.financing.income.RequireLeefVorm = this.showHouseholdQuestions;
      },
    },
    beforeRouteLeave() {
      LeadDataService.pushRemoteData(this.uiStore.uuid, true);
    }
  }
</script>

<style lang="scss">
</style>