import { defineStore } from 'pinia';
import dummyLead from '@/assets/data/dummyLead-complete';
import { isFunction } from '@vue/shared';
import { get, merge, remove } from 'lodash';
import { qualifiedSectionKeys } from '@/store/QualificationStore'
import { parsePhoneNumber } from 'libphonenumber-js';
import { useInterfaceStore } from './UiStore';
import { getInterestDefinition } from '@/rules/InterestDefinitions';
import arrayIncludes from '@/helpers/ArrayIncludes';

export const useLeadStore = defineStore({
  // id is required so that Pinia can connect the store to the devtools
  id: 'LeadStore',
  state: () => ({
    meta: {
      PartnerCoupon: null,
      CallBackRequested: false,
      InternalUserId: null, // Salesforce UserId when used by employee
      ReturnUrl: null, // url to return
      CallbackUrl: null, // id to supply when submitting result
      PortalId: null, // id in klantenportaal
      LowQualitySource: true,
      LeadSource: null,
      AllInterestsEnabled: false,
      SendConfirmation: true
    },
    interests: {
      Interests: [],
      UrgencyRating: null,
      Motivation: null,
    },
    contact: {
      Salutation: null,
      FirstName: null,
      LastName: null,
      Email: null,
      Phone: null,
    },
    address: {
      id: null,
      eligible: null,
      Street: null,
      StreetNumber: null,
      Street_number_addition__c: null,
      Street_number_letter__c: null,
      PostalCode: null,
      City: null,
      BAG_Object_ID__c: null,
      BAG_nummeraanduidingIdentificatie__c: null,
      Energielabel_datum__c: null,
      Energielabel__c: null,
      BAG_openbareRuimteNaam__c: null,
      BAG_korteNaam__c: null,
      BAG_woonplaatsNaam__c: null,
      BAG_huisnummer__c: null,
      BAG_postcode__c: null,
      BAG_openbareRuimteIdentificatie__c: null,
      BAG_woonplaatsIdentificatie__c: null,
      BAG_adresregel5__c: null,
      BAG_adresregel6__c: null,
      BAG_typeAdresseerbaarObject__c: null,
      BAG_oppervlakte__c: null,
      BAG_gebruiksdoelen__c: null,
      BAG_oorspronkelijkBouwjaar__c: null,
      BAG_pandStatussen__c: null,
      BAG_self_link__c: null,
      BAG_openbareRuimte_link__c: null,
      BAG_nummeraanduiding_link__c: null,
      BAG_woonplaats_link__c: null,
      BAG_adresseerbaarObject_link__c: null,
      BuildingCluster__c: null,
      EP_postcode__c: null,
      EP_afschrift__c: null,
      EP_gebouwtype__c: null,
      EP_huisletter__c: null,
      EP_huisnummer__c: null,
      EP_gebouwklasse__c: null,
      EP_berekeningstype__c: null,
      EP_metingGeldigTot__c: null,
      EP_detailaanduiding__c: null,
      EP_bagVerblijfsobjectId__c: null,
      EP_huisnummertoevoeging__c: null,
      EP_isVereenvoudigdLabel__c: null,
      EP_isOpBasisVanReferentiegebouw__c: null,
      created_at: null,
      last_updated: null
    },
    ownership: {
      PropertyOwner: null,
      HoaMember: null,
      HoaPermission: null,
      HoaRepName: null,
      HoaRepPhone: null,
      HoaRepEmail: null,
    },
    advice: {
      ConsentForContact: null,
      HelpWithQuotes: null,
      HelpWithVHF: null
    },
    contactPreferences: {
      PreferredContactTime: 'Geen voorkeur',
      SpeaksDutch: null,
      PreferredLanguage: null,
    },
    financing: {
      snnSubsidyVenV: {
        InVersterkingVoor2020: null, // added nov 24
        VerouderdVersterkingsAdvies: null, // added nov 24
        RequestedSnn7k: null, // added nov 24
        InVersterking: null, // present since v1
        RequestedSnn10k: null, // present since v1
        WoningInBlokB: null, // added nov 24
        RequestedSnn17k: null, // added nov 24
        RequestedSnn30k: null // added nov 24
      },
      snnSubsidy4k: {
        RequestedSnn4k: null
      },
      income: {
        ReceivedEnergySupport: null, // energietoeslag ontvangen in 2023 https://www.bwri.nl/energietoeslag-2023-aanvragen
        // leef situatie required
        RequireLeefVorm: null, // leefvorm uitvragen
        CivilStatus: null, // burgerlijke staat
        AgeBracket: null, // leeftijdscategorie
        AgeBracketPartner: null, // leeftijdscategorie partner
        HasChildren: null,
        // statically updated
        CalculatedSocialMinimum: null, // berekend sociaal minimum
        AppliedNettMinimumWages: {}, // aangehouden minimumloon (netto) (object)
        SM_100pct: null, // -100% van sociaal minimum
        SM_110pct: null, // -110% van sociaal minimum
        SM_125pct: null, // -125% van sociaal minimum
        SM_140pct: null, // -140% van sociaal minimum
        WML_100pct: null, // -100% van minimumloon
        WML_125pct: null, // -125% van minimumloon
        WML_140pct: null, // -140% van minimumloon
        over_WML_140pct: null, // > 140% van minimumloon
      },
      nip: {
        IncomeOver125pct: null, // 125% van minimumloon (2023) (extra naast income categories)
        PermissionToRequestNip: null,
      },
      maatregel29: {
        IncomeOver140pct: null // deprecated, replaced by income categories in .income
      },
      warmtefonds: {
        WantEblWarmtefonds: null, // Energiebespaarflening Warmtefonds
      },
      stimuleringslening: {
        WantStimuleringsleningMG: null, // stimuleringslening Midden-Groningen
      },
      mortgage: {
        HasRaboMortgageAndLabelFG: null,
        WantUpdateMortgage: null
      }
    },
    practicalHelp: {
      WantPracticalHelp: null,
    },
    property: {
      ConstructionYear: null,
      GasConsumption: null,
      PowerConsumption: null,
      HouseType: null,
    },
    cavity_wall: {
      general: {
        CavityWallPresent: null,
        FacadeMaterial: null,
      },
      paint: {
        WallsPainted: null,
        AcceptWallRepaint: null,
      },
      stucco: {
        WallsPlastered: null,
        AcceptStuccoRepaint: null,
      },
    },
    floor: {
      general: {
        SpaceBeneathFloor: null,
        CrawlSpaceAccessible: null,
      },
      details: {
        CrawlSpaceHeightCm: null,
        FloorInsulationSide: null,
      },
    },
    roof: {
      general: {
        RoofInsulationStatus: null,
        RoofInsulationSide: null,
        // RoofReplacementWithinDecade: null,
        RoofType: null
      },
      attic: {
        AtticPresent: null,
        AccessToRoofInside: null,
        AtticUsage: [],
      },
      finishing: {
        RoofBeamsVisible: null,
        RoofInsideFinishingPresent: null,
        RoofFinishingRemovalPermission: null,
        RoofBeamCoveringRemoved: null,
      },
    },
    glazing: {
      general: {
        IsMonument: null,
        BuildingAccessibleAllround: null,
        CurrentGlazingType: null,
        CurrentGlazingAge: null,
      },
      frame: {
        RebateWidthSufficient: null,
        FrameMaterial: null,
        FrameCondition: null,
      },
      dimensions: {
        Panes: [
          {
            width: null,
            height: null
          }
        ]
      }
    },
    heatpump: {
      HeatingSystem: null,
      HeatpumpFinancing: null
    },
    solar: {
      PlacementLocations: []
    }
  }),
  getters: {
    // utility getters
    $fields(state) {
      return Object.keys(state)
        .filter(function (key) {
          return (
            key.charAt(0) !== '$' &&
            key.charAt(0) !== '_' &&
            !isFunction(state[key])
          );
        })
        .reduce(function (fields, key) {
          fields[key] = state[key];
          return fields;
        }, {});
    },

    // field getters
    interestsArray(state) {
      if (!state.interests || !Array.isArray(state.interests.Interests)) {
        return [];
      }
      return state.interests.Interests;
    },
    getAtticUsage(state) {
      return Array.isArray(state.roof.attic.AtticUsage)
        ? state.roof.attic.AtticUsage
        : [];
    },
    AtticUsedForStorage(state) {
      return state.getAtticUsage.includes('storage');
    },
    AtticUsedForLiving(state) {
      return state.getAtticUsage.includes('living');
    },
    skipRoofFinishing(state) {
      return state.roof.attic.AccessToRoofInside == 'Vlizotrap';
    },
    /**
     * Should only return the following values:
     * enkel-voor-1960
     * enkel-na-1960
     * dubbel-voor-1995
     * dubbel-na-1995
     * null
     */
    GlazingToReplace(state) {
      let type = state.glazing.general.CurrentGlazingType;
      let age = state.glazing.general.CurrentGlazingAge;
      return type && age ? type + '-' + age : null;
    },

    GlazingDimensionsText(state) {
      let text = '';
      let panes = state.glazing.dimensions.Panes;
      if (!Array.isArray(panes) || panes.length < 1) { return text; }
      text = "Afmetingen ruiten (breedte x hoogte): \n";
      // iterate through panes and generate text
      for (let i = 0; i < panes.length; i++) {
        text += "Ruit " + (i+1) + ": " + panes[i].width + ' x ' + panes[i].height + ' cm';
        if (i < panes.length - 1) {
          text += "\n";
        }
      }
      return text;
    },

    /**
     * returns all qualified interests, and choosing floor or crawl space based on FloorInsulationSide
     * @returns {Array<string>}
     */
    QualifiedInterests(state) {
      let qualifiedSections = qualifiedSectionKeys.value;
      if (!Array.isArray(qualifiedSections)) { return []; }
      if (qualifiedSections.includes('floor')) {
        if (get(state, 'floor.details.FloorInsulationSide', null) == 'Bodem') {
          remove(qualifiedSections, function(value) {
            return value == 'floor';
          })
          qualifiedSections.push('crawl_space');
        }
      }
      return qualifiedSections;
    },

    FormattedPhone(state) {
      if (!state.contact.Phone) { return null; }
      let phoneNumber = parsePhoneNumber(state.contact.Phone, 'NL');
      return phoneNumber.formatInternational();
    },

    PrefilledCalendlyUrl(state) {
      if (!state.contact.Phone) return null;
      const uiStore = useInterfaceStore();
      const trimmedFormattedPhone = this.FormattedPhone.replaceAll(' ', '');
      let url = `https://portal.bureauverduurzamen.nl/belafspraak/lead/${uiStore.uuid}?first_name=${state.contact.FirstName}&last_name=${state.contact.LastName}&email=${state.contact.Email}&phone=${trimmedFormattedPhone}`;
      // if interests contains 'solar' or 'heatpump', add alternate advisor
      if (arrayIncludes('heatpump', state.interests.Interests)) {
        url += '&event_slug=telefonisch-adviesgesprek-met-wp';
      }
      return url;
    },

    EP_D_or_lower(state) {
      if (state.address.Energielabel__c == 'D' || state.address.Energielabel__c == 'E' || state.address.Energielabel__c == 'F' || state.address.Energielabel__c == 'G') {
        return true;
      }
      return false;
    },

    NIP_address(state) {
      // whether NIP is available for address (not taking other factors into account)
      if (!state.address || !state.address.Municipality && !state.address.City) {
        return false;
      } else {
        return state.address.Municipality == 'Midden-Groningen' || state.address.City == 'Hoogezand';
      }
    },
    Eligible_Handyman(state) {
      if (!state.address || !state.address.Municipality && !state.address.City) {
        return false;
      } else {
        return state.address.Municipality == 'Midden-Groningen' || state.address.City == 'Hoogezand';
      }
    },
    stimuleringslening_address(state) {
      // whether NIP is available for address (not taking other factors into account)
      if (!state.address || !state.address.Municipality && !state.address.City) {
        return false;
      } else {
        return state.address.Municipality == 'Midden-Groningen' || state.address.City == 'Hoogezand';
      }
    },
    
    hasIncomeCategory(state) {
      if (
        state.financing.income.SM_100pct ||
        state.financing.income.SM_110pct ||
        state.financing.income.SM_125pct ||
        state.financing.income.SM_140pct ||
        state.financing.income.WML_100pct ||
        state.financing.income.WML_125pct ||
        state.financing.income.WML_140pct ||
        state.financing.income.over_WML_140pct
      ) {
        return true;
      }
      return false;
    }
  },
  actions: {
    loadRemoteData(remoteData = null) {
      if (remoteData) {
        merge(this.$state, remoteData);
      }
    },
    loadDummyData() {
      // Object.assign(this.state, dummyLead);
      this.$state = { ...this.$state, ...dummyLead };
    },
    checkInterest(interestValue = null) {
      const definition = getInterestDefinition(interestValue);
      if (interestValue && definition !== null && !this.$state.interests.Interests.includes(interestValue)) {
        this.$state.interests.Interests.push(interestValue);
        return definition;
      }
      return null;
    },
    setLeadSource(leadSource = null) {
      if (leadSource) {
        this.$state.meta.LeadSource = leadSource;
      }
    },
    /* backfill pane ids on panes without id, only to be as key attribute on v-for items
    purpose is to ensure correct behaviour when deleting pane in middle of array */
    backfillPaneIds() {
      let panes = this.$state.glazing.dimensions.Panes;
      if (!Array.isArray(this.$state.glazing.dimensions.Panes)) {
        // set to empty array
        this.$state.glazing.dimensions.Panes = [];
        return;
      }
      // iterate through panes and generate ids (new Date().getTime()) if not set
      for (let i = 0; i < panes.length; i++) {
        if (!panes[i].id) {
          panes[i].id = new Date().getTime() + i;
        }
      }
      this.$state.glazing.dimensions.Panes = panes;
    }
  },
});
