// bron: https://open.overheid.nl/documenten/8302c040-99b9-45fb-b5db-2df56db39336/file
// nieuwe bron 2025: https://gemeente.groningen.nl/bijstandsnormen-gemeente-groningen
export const socialMinimums = {
  alleenstaand_21_pensioen: 1278.18,
  alleenstaand_pensioen: 1426.01,
  samen_21_pensioen: 1825.96,
  samen_pensioen: 1950.81,
  // special for 18-21
  alleenstaand_18_21_geen_kinderen: 315.56,
  alleenstaand_18_21_met_kinderen: 315.56,
  samen_18_21_geen_kinderen: 631.12,
  samen_18_21_met_kinderen: 996.31,
  samen_1onder21_geen_kinderen: 1228.55,
  samen_1onder21_met_kinderen: 1593.74
}

export const civilStatusOptions = [
  {
    label: 'Alleenstaand',
    value: 'Alleenstaand'
  },
  {
    label: 'Gehuwd of samenwonend',
    value: 'Gehuwd of samenwonend'
  }
];

export const ageBrackets = [
  {
    label: '18 t/m 20 jaar',
    value: '18 tot 21 jaar'
  },
  {
    label: '21 jaar tot pensioenleeftijd',
    value: '21 tot pensioenleeftijd'
  },
  {
    label: 'Pensioenleeftijd',
    value: 'Pensioenleeftijd'
  }
];

/**
 * Function to get the social minimum based on the given parameters
 * @param {String} AgeBracket value from ageBrackets options
 * @param {String} AgeBracketPartner value from ageBrackets options, optional
 * @param {String} CivilStatus value from civilStatusOptions
 * @param {Boolean} HasChildren with children or not (true/false respectively)
 * @returns Decimal value - social minimum (100%)
 */
export function getSocialMinimum(AgeBracket, AgeBracketPartner = null, CivilStatus, HasChildren) {
  // pensioners
  if (AgeBracket == 'Pensioenleeftijd' || AgeBracketPartner == 'Pensioenleeftijd') {
    return CivilStatus == 'Gehuwd of samenwonend'
      ? socialMinimums.samen_pensioen
      : socialMinimums.alleenstaand_pensioen;
  }
  // single and 18-21
  if (AgeBracket == '18 tot 21 jaar' && CivilStatus == 'Alleenstaand') {
    return HasChildren
      ? socialMinimums.alleenstaand_18_21_met_kinderen
      : socialMinimums.alleenstaand_18_21_geen_kinderen;
  }
  // if not single and both 18-21
  if (AgeBracket == '18 tot 21 jaar' && AgeBracketPartner == '18 tot 21 jaar') {
    return HasChildren
      ? socialMinimums.samen_18_21_met_kinderen
      : socialMinimums.samen_18_21_geen_kinderen;
  }
  // if not single and either is 18-21
  if (AgeBracket == '18 tot 21 jaar' || AgeBracketPartner == '18 tot 21 jaar') {
    return HasChildren
      ? socialMinimums.samen_1onder21_met_kinderen
      : socialMinimums.samen_1onder21_geen_kinderen;
  }
  if (AgeBracket == '21 tot pensioenleeftijd') {
    return CivilStatus == 'Gehuwd of samenwonend'
      ? socialMinimums.samen_21_pensioen
      : socialMinimums.alleenstaand_21_pensioen;
  }
  return null;
}

export function testSocialMinimums() {
  const testCases = [
    {
      AgeBracket: '18 tot 21 jaar',
      AgeBracketPartner: null,
      CivilStatus: 'Alleenstaand',
      HasChildren: false,
      Expected: 306.88
    },
    {
      AgeBracket: '18 tot 21 jaar',
      AgeBracketPartner: null,
      CivilStatus: 'Alleenstaand',
      HasChildren: true,
      Expected: 306.88
    },
    {
      AgeBracket: '18 tot 21 jaar',
      AgeBracketPartner: '18 tot 21 jaar',
      CivilStatus: 'Gehuwd of samenwonend',
      HasChildren: false,
      Expected: 613.76
    },
    {
      AgeBracket: '18 tot 21 jaar',
      AgeBracketPartner: '18 tot 21 jaar',
      CivilStatus: 'Gehuwd of samenwonend',
      HasChildren: true,
      Expected: 968.91
    },
    {
      AgeBracket: '18 tot 21 jaar',
      AgeBracketPartner: '21 tot pensioenleeftijd',
      CivilStatus: 'Gehuwd of samenwonend',
      HasChildren: false,
      Expected: 1194.76
    },
    {
      AgeBracket: '18 tot 21 jaar',
      AgeBracketPartner: '21 tot pensioenleeftijd',
      CivilStatus: 'Gehuwd of samenwonend',
      HasChildren: true,
      Expected: 1549.91
    },
    {
      AgeBracket: '18 tot 21 jaar',
      AgeBracketPartner: 'Pensioenleeftijd',
      CivilStatus: 'Gehuwd of samenwonend',
      HasChildren: false,
      Expected: 1877.81 // tja, welk vakje in de tabel moeten we hier aanhouden?
    },
    {
      AgeBracket: '21 tot pensioenleeftijd',
      AgeBracketPartner: 'Pensioenleeftijd',
      CivilStatus: 'Gehuwd of samenwonend',
      HasChildren: false,
      Expected: 1877.81
    },
    {
      AgeBracket: 'Pensioenleeftijd',
      AgeBracketPartner: null,
      CivilStatus: 'Alleenstaand',
      HasChildren: false,
      Expected: 1384.01
    },
    {
      AgeBracket: '21 tot pensioenleeftijd',
      AgeBracketPartner: '21 tot pensioenleeftijd',
      CivilStatus: 'Gehuwd of samenwonend',
      HasChildren: false,
      Expected: 1775.75
    },
    {
      AgeBracket: '21 tot pensioenleeftijd',
      AgeBracketPartner: null,
      CivilStatus: 'Alleenstaand',
      HasChildren: false,
      Expected: 1243.03
    }
  ];

  const failed = [];
  const success = [];
  testCases.forEach((testCase, index) => {
    const result = getSocialMinimum(testCase.AgeBracket, testCase.AgeBracketPartner, testCase.CivilStatus, testCase.HasChildren);
    console.log({
      status: result === testCase.Expected ? 'PASSED' : 'FAILED',
      expected: testCase.Expected,
      received: result
    });
    if (result === testCase.Expected) {
      success.push(index);
    } else {
      failed.push(index);
    }
  });
  
  console.log(`\n${success.length} test cases passed, ${failed.length} test cases failed.`);
}