<template>
  <interview-layout :touch-and-validate="touchAndValidate">
    <!-- inner wrapper used for centering -->
    <div style="max-width: 438px;">
      <!-- title above form card -->
      <h1 class="px-4 text-primary fade-in-up-enter-active animation-slowest">{{ greeting }}</h1>
      <h4 class="px-4 pb-3 fade-in-up-enter-active animation-slowest delay-200">{{ greetingQuestion }}</h4>
      <!-- form card -->
      <base-card inner-class="w-100" color="alternate" :outer-padding="true">
        <base-form @submit="onSubmit">
          <!-- inputs -->
          <base-input name="Salutation"
            type="radiobuttons"
            :options="salutationOptions"
            placeholder="Kies een optie"
            input-class="small"
            :display-optional="true"
            v-model:value="lead.contact.Salutation" :validator="v$.contact.Salutation">
            Aanhef
          </base-input>
          <base-input name="FirstName" 
            v-model:value="lead.contact.FirstName" :validator="v$.contact.FirstName">
            Voornaam
          </base-input>
          <base-input name="LastName" 
            v-model:value="lead.contact.LastName" :validator="v$.contact.LastName">
            Achternaam
          </base-input>
          <base-input name="Email" type="email" placeholder="test@example.com" 
            v-model:value="lead.contact.Email" :validator="v$.contact.Email" :debounce="700" :debounce-touch-only="true">
            E-mailadres
          </base-input>
          <base-input name="Phone" type="phone" placeholder="+316 00 00 00 00" 
            v-model:value="lead.contact.Phone" :validator="v$.contact.Phone">
            Telefoonnummer
          </base-input>
          <div class="mt-4 mb-n2">
            <span class="fst-italic small">Bij aanvraag gaat u akkoord met onze <a href="https://bureauverduurzamen.nl/privacy/" target="_blank">privacyverklaring</a>.</span>
          </div>
          <!-- buttons -->
          <button-row class="mt-4">
            <base-button class="ms-auto" icon="bi-arrow-right" type="submit" :disabled="v$.contact.$invalid">Volgende</base-button>
          </button-row>
        </base-form>
      <!-- form card end -->
      </base-card>
      <!-- inner wrapper end -->
    </div>
  </interview-layout>
</template>

<script>
  import InterviewLayout from "@/views/layouts/InterviewLayout.vue"
  import BaseCard from '@/components/BaseCard.vue'
  import BaseForm from "@/components/BaseForm.vue"
  import BaseInput from "@/components/BaseInput.vue"
  import BaseButton from "@/components/BaseButton.vue"
  import ButtonRow from "@/components/ButtonRow.vue"

  import { useAllStores } from "@/helpers/UseAllStores"
  import { CommonComposable } from "@/helpers/CommonComposable"
  import { FlowService } from "@/services/FlowService"

  export default {
    components: {
      InterviewLayout,
      BaseCard,
      BaseForm,
      BaseInput,
      BaseButton,
      ButtonRow
    },
    setup(props, context) {
      return { ...CommonComposable(props, context), ...useAllStores() };
    },
    data() {
      return {
        salutationOptions: [
          {
            label: 'Dhr.',
            value: 'dhr.'
          },
          {
            label: 'Mevr.',
            value: 'mevr.'
          },
          {
            label: 'Anders',
            value: 'anders'
          }
        ],
      }
    },
    computed: {
      greetingQuestion() {
        return 'Laat uw gegevens achter om een afspraak in te plannen.'
        // if (this.uiStore.isExistingCustomer) {
        //   return 'Kloppen deze gegevens?';
        // } else {
        //   return 'Hoe kunnen we u bereiken?';
        // }
      },
      greeting() {
        return 'Aanmelden energieadvies'
        // if (this.uiStore.isExistingCustomer) {
        //   if (this.lead.contact.FirstName) {
        //     return 'Hallo '+this.lead.contact.FirstName+'!';
        //   } else {
        //     return 'Hallo!';
        //   }
        // } else {
        //   return 'Hallo!'
        // }
      }
    },
    methods: {
      touchAndValidate() {
        this.v$.contact.$touch();
        return !this.v$.contact.$invalid;
      },
      onSubmit() {
        if (this.touchAndValidate()) {
          FlowService.pushNext(this);
        }
      },
    }
  }
</script>

<style lang="scss">
</style>