export const miniumMonthlyWages = {
  2023: {
    h1: 1934,
    h2: 1995
  },
  2024: {
    h1: 2069.40,
    h2: 2132.52
  },
  2025: {
    h1: 2070.12
  }
}

export const minimumMonthlyNettWages = {
  2024: {
    h2: {
      nett100pct: 2053,
      nett125pct: 2367.42,
      nett140pct: 2556.12
    }
  },
  2025: {
    h1: {
      nett100pct: 1983.20,
      nett125pct: 2346.57,
      nett140pct: 2533.25
    }
  }
};

/**
 * Bereken totaal minimumloon over het hele jaar, rekening houdend met verhoging per 1 juli
 * @param {Integer} year jaar waarvoor het minimumloon moet worden berekend. Hierbij wordt een middeling gemaakt tussen h1 en h2
 * @param {Decimal} multiplier default 1, kan worden gebruikt om het minimumloon te vermenigvuldigen met een factor
 * @param {Boolean} rounded default true
 * @param {Int} precision aantal decimalen waarop moet worden afgerond
 * @returns Decimal
 */
export function getAnnualMinimumWage(year, multiplier = 1, rounded = true, precision = 2) {
  let total = (miniumMonthlyWages[year].h1 * 6) + (miniumMonthlyWages[year].h2 * 6);
  total *= multiplier;

  // if rounded = true, round dynamically using pow and precision
  if (rounded) {
    const pow = Math.pow(10, precision);
    total = Math.round(total * pow) / pow;
  }

  return total;
}

/**
 * Minimum loon per maand
 * @param {Integer} year jaar waarvoor het minimumloon moet worden opgehaald
 * @param {Integer} half helft van het jaar (1 or 2) (default 2)
 * @returns Decimal
 */
export function getMonthlyMinimumWage(year, half = 2, multiplier = 1, rounded = true, precision = 2) {
  let monthlyWage = miniumMonthlyWages[year][`h${half}`] * multiplier;
  if (rounded) {
    const pow = Math.pow(10, precision);
    monthlyWage = Math.round(monthlyWage * pow) / pow;
  }
  return monthlyWage;
}

export function test() {
  const testCases = [
    {
      year: 2023,
      expected: 23574,
      multiplier: 1
    },
    {
      year: 2024,
      expected: 25211.52,
      multiplier: 1
    },
    {
      year: 2023,
      expected: 29467.5,
      multiplier: 1.25
    }
  ];
  // iterate over test cases
  testCases.forEach(testCase => {
    const result = getAnnualMinimumWage(testCase.year, testCase.multiplier);
    console.assert(result === testCase.expected, `Expected ${testCase.expected}, but got ${result}`);
  });
}