<template>
  <interview-layout :touch-and-validate="touchAndValidate">
    <!-- inner wrapper used for centering -->
    <div class="">
      <!-- title above form card -->
      <h1 class="px-4 pb-3 fade-in-up-enter-active animation-slowest max-100 text-primary">SNN subsidie</h1>
      <h5 class="px-4 pb-3 fade-in-up-enter-active animation-slowest delay-200 max-100">Verduurzaming en Verbetering</h5>
      <div class="d-flex flex-column flex-lg-row justify-content-center">

        <!-- form 1 card -->
        <base-card inner-class="container-narrow w-100" color="alternate" :outer-padding="true">
          <base-form @submit="onSubmit">

            <!-- <div class="small mb-4">
                <checkmark-item class="mb-2">
                  Het Samenwerkingsverband Noord-Nederland (SNN) heeft subsidieregelingen voor woningeigenaren die in het aardbevingsbebied wonen of erkende bevingsschade hebben.
                </checkmark-item>
                <checkmark-item class="mb-2">
                  Middels deze vragen controleren we of u in aanmerking komt voor een aanvraag.
                </checkmark-item>
            </div> -->

            <!-- new inputs, options yesNoDontknow -->
            <!-- 1. Zat uw woning op of vóór 6 november 2020 in het versterkingsprogramma van het NCG? -->
            <base-input
              type="radiobuttons" :options="yesNoDontKnow"
              name="InVersterkingVoor2020"
              v-model:value="lead.financing.snnSubsidyVenV.InVersterkingVoor2020" :validator="v$.financing.snnSubsidyVenV.InVersterkingVoor2020">
              Zat uw woning <strong>op of vóór 6 november 2020</strong> in het versterkingsprogramma van het NCG?
            </base-input>

            <!-- If 1 = ja: 2. Is dat versterkingsadvies gemaakt met verouderde inzichten? -->
            <div v-if="showVerouderdAdviesQuestions">
              <base-input
                type="radiobuttons" :options="yesNoDontKnow"
                name="VerouderdVersterkingsAdvies"
                v-model:value="lead.financing.snnSubsidyVenV.VerouderdVersterkingsAdvies" :validator="v$.financing.snnSubsidyVenV.VerouderdVersterkingsAdvies">
                Heeft u een versterkingsadvies dat werd gemaakt met verouderde inzichten?
              </base-input>
            </div>

            <!-- section SNN 7000 euro -->
            <div v-show="showSubsidy7kRequestedQuestions" class="fade-in-up-enter-active animation-slowest delay-200">
              <h6 class="pb-3 mt-5 max-100">Subsidie Verduurzaming Groningen – &euro;7.000</h6>

              <div class="small mb-5">
                <checkmark-item class="mb-2" icon-color="warning">
                  Het gebouw heeft een versterkingsadvies dat gemaakt is met verouderde inzichten. Dit betekent dat de beoordeling gedaan is met inzichten die nog geen rekening houden met het stoppen van de gaswinning in 2022 (NPR:9998: 2018 tijdvak 2 of eerder).
                </checkmark-item>
                <checkmark-item class="mb-2" icon-color="warning">
                  U vraagt maximaal twee keer deze subsidie aan, tot een maximum van 7.000 euro.
                </checkmark-item>
                <p>
                  Onder deze voorwaarden kan het zijn u in aanmerking komt voor een speciale subsidie voor eigenaren die een versterkingsadvieskregen op basis van verouderde inzichten. Lees meer over deze subsidie op de 
                  <a href="https://www.snn.nl/subsidies-voor-particulieren/subsidie-verduurzaming-groningen-eu-7000" target="_blank">de website van het SNN</a>.
                </p>
              </div>
              
              <!-- If 2 = Ja: 3. Is er eerder de subsidie Verduurzaming Groningen (&euro;7.000) aangevraagd voor uw woning? -->
               <div>
                <base-input
                  type="radiobuttons" :options="yesNoDontKnow"
                  name="RequestedSnn7k"
                  v-model:value="lead.financing.snnSubsidyVenV.RequestedSnn7k" :validator="v$.financing.snnSubsidyVenV.RequestedSnn7k">
                  Is deze subsidie al twee keer aangevraagd en/of is het maximum van &euro;7.000 bereikt voor uw woning?
                </base-input>
              </div>
            </div>            

            <!-- inputs -->
            <base-input
              type="radiobuttons" :options="yesNoDontKnow"
              name="InVersterking"
              v-model:value="lead.financing.snnSubsidyVenV.InVersterking" :validator="v$.financing.snnSubsidyVenV.InVersterking">
              Is uw woning <strong>nu</strong> onderdeel van het versterkingsprogramma van Nationaal Coördinator Groningen?
            </base-input>

            <!-- section SNN 10000 euro -->
            <div v-show="showSubsidy10kRequestedQuestions" class="fade-in-up-enter-active animation-slowest delay-200">
              <h6 class="pb-3 mt-5 max-100">Subsidie Verduurzaming en Verbetering Groningen – &euro;10.000</h6>

              <div class="small mb-5">
                <checkmark-item class="mb-2" :icon-color="lead.address.eligible ? 'success' : 'warning'">
                  {{ lead.address.eligible 
                    ? 'Uw woning valt binnen het aangewezen postcodegebied.'
                    : 'Valt uw postcode binnen het aangewezen postcodegbied?'}}
                </checkmark-item>
                <checkmark-item class="mb-2" :icon-color="lead.financing.snnSubsidyVenV.InVersterking == 'Nee' ? 'success' : 'warning'">
                  {{ lead.financing.snnSubsidyVenV.InVersterking == 'Nee'
                    ? 'Uw woning valt niet binnen het versterkingsprogramma van NCG.'
                    : 'En valt uw woning niet binnen het versterkingsprogramma van NCG?' }}
                </checkmark-item>
                <p>
                  Onder deze voorwaarden komt u waarschijnlijk in aanmerking voor deze subsidie die u kunt besteden aan verduurzamende of verbeterende maatregelen aan uw woning of bijgebouw. Meer informatie vind u op 
                  <a href="https://www.snn.nl/subsidies-voor-particulieren/subsidie-verduurzaming-en-verbetering-groningen-eu-10000" target="_blank">de website van het SNN</a>.
                </p>
              </div>

              <base-input
                type="radiobuttons" :options="yesNoDontKnow"
                name="RequestedSnn10k"
                v-model:value="lead.financing.snnSubsidyVenV.RequestedSnn10k" :validator="v$.financing.snnSubsidyVenV.RequestedSnn10k">
                Is er eerder de subsidie Verduurzaming en Verbetering Groningen (&euro;10.000) aangevraagd voor uw woning?
              </base-input>
            </div>

            <!-- subsidie voor woningen in versterkingsopgave -->
            <div v-show="showSubsidy17k30kQuestions" class="fade-in-up-enter-active animation-slowest delay-200">
              <h6 class="pb-3 mt-5 max-100">Subsidie voor woningen in versterkingsopgave</h6>

              <base-input
                type="radiobuttons" :options="yesNoDontKnow"
                name="WoningInBlokB"
                v-model:value="lead.financing.snnSubsidyVenV.WoningInBlokB" :validator="v$.financing.snnSubsidyVenV.WoningInBlokB">
                Heeft u van de gemeente een brief gehad dat u in aanmerking komt voor de gemeentelijke regeling voor moeilijk uitlegbare verschillen (Blok B)?
              </base-input>

              <div v-if="lead.financing.snnSubsidyVenV.WoningInBlokB === 'Ja'" class="small mb-5">
                <checkmark-item class="mb-2" icon-color="success">
                  De meeste eigenaren in Blok B komen in aanmerking voor een subsidie van 17.000 euro voor het verbeteren en verduurzamen van hun woning. Daarnaast kun je ook een vrij te besteden bedrag van 13.000 euro aanvragen.
                </checkmark-item>
                <checkmark-item class="mb-2" icon-color="warning">
                  Je hebt twee aanvraagmomenten om een aanvraag in te dienen. Dit geldt per adres en dit mag tot het maximum van 7.000 of 17.000 euro.
                </checkmark-item>
                <checkmark-item class="mb-2" icon-color="warning">
                  Als je in aanmerking komt voor de subsidie van 17.000 euro, kom je ook in aanmerking voor het vrij te besteden bedrag van 13.000 euro. Dit hoeft niet op hetzelfde moment.
                </checkmark-item>
                <p>
                  Lees meer over deze regeling op de 
                  <a href="https://www.snn.nl/subsidies-voor-particulieren/subsidie-blok-b" target="_blank">website van het SNN</a>.
                </p>

                <base-input
                  type="radiobuttons" :options="yesNoDontKnow"
                  name="RequestedSnn30k"
                  v-model:value="lead.financing.snnSubsidyVenV.RequestedSnn30k" :validator="v$.financing.snnSubsidyVenV.RequestedSnn30k">
                  Heeft u deze subsidies van &euro;17.000 en &euro;13.000 al aangevraagd?
                </base-input>
              </div>

              <div v-else class="small mb-5">
                <checkmark-item class="mb-2" icon-color="success">
                  Voor eigenaren van gebouwen die in het versterkingsprogramma van NCG zitten en een beoordeling met nieuwe inzichten hebben of krijgen, of een beoordeling met verouderde inzichten hebben en daaruit blijkt dat geen versterking nodig is, is er een subsidie van &euro;17.000 dat besteed kan worden aan het verbeteren en/of verduurzamen van uw woning, gebouw of bijgebouwen op hetzelfde adres.
                </checkmark-item>
                <p>
                  Lees meer over deze subsidie op de 
                  <a href="https://www.snn.nl/subsidies-voor-particulieren/subsidie-verduurzaming-en-verbetering-groningen-eu-17000" target="_blank">website van het SNN</a>.
                </p>

                <base-input
                  type="radiobuttons" :options="yesNoDontKnow"
                  name="RequestedSnn17k"
                  v-model:value="lead.financing.snnSubsidyVenV.RequestedSnn17k" :validator="v$.financing.snnSubsidyVenV.RequestedSnn17k">
                  Is er eerder de subsidie Verduurzaming en Verbetering Groningen van &euro;17.000 aangevraagd voor uw woning?
                </base-input>
              </div>
            </div>

          </base-form>
        <!-- form card end -->
        </base-card>
      
      <!-- cards wrapper end -->
      </div>
      <button-row class="mt-2 flex-row-reverse">
        <base-button class="ms-auto" icon="bi-arrow-right" @click="onSubmit" :disabled="v$.financing.snnSubsidyVenV.$invalid">Volgende</base-button>
        <base-button variant="minimal-secondary" icon="bi-arrow-left" icon-position="start" @click="onPrev">Terug</base-button>
      </button-row>
      <!-- inner wrapper end -->
    </div>
  </interview-layout>
</template>

<script>
  import InterviewLayout from "@/views/layouts/InterviewLayout.vue"
  import BaseCard from '@/components/BaseCard.vue'
  import BaseForm from "@/components/BaseForm.vue"
  import BaseInput from "@/components/BaseInput.vue"
  import BaseButton from "@/components/BaseButton.vue"
  import ButtonRow from "@/components/ButtonRow.vue"
  // import VariablePill from "@/components/VariablePill.vue"
  import CheckmarkItem from "@/components/CheckmarkItem.vue"
  // import IssueFeedback from "@/components/IssueFeedback.vue"

  import { useAllStores } from "@/helpers/UseAllStores"
  import { CommonComposable } from "@/helpers/CommonComposable"
  import { FlowService } from "@/services/FlowService"

  export default {
    components: {
      InterviewLayout,
      BaseCard,
      BaseForm,
      BaseInput,
      BaseButton,
      ButtonRow,
      // VariablePill,
      CheckmarkItem,
      // IssueFeedback
    },
    setup(props, context) {
      return { ...CommonComposable(props, context), ...useAllStores() };
    },
    data() {
      return {
        yesNo: [
          {
            label: 'Ja',
            value: true
          },
          {
            label: 'Nee',
            value: false
          },
        ],
        yesNoDontKnow: [
          {
            label: 'Ja',
            value: 'Ja'
          },
          {
            label: 'Nee',
            value: 'Nee'
          },
          {
            label: 'Weet ik niet',
            value: 'Weet ik niet'
          }
        ]
      };
    },
    computed: {
      showVerouderdAdviesQuestions() {
        return (
          this.lead.financing.snnSubsidyVenV.InVersterkingVoor2020 === 'Ja'
          || this.lead.financing.snnSubsidyVenV.InVersterkingVoor2020 == 'Weet ik niet');
      },
      showSubsidy7kRequestedQuestions() {
        return (
          this.showVerouderdAdviesQuestions
          && this.lead.financing.snnSubsidyVenV.VerouderdVersterkingsAdvies == 'Ja');
      },
      showSubsidy10kRequestedQuestions() {
        return (
          this.lead.financing.snnSubsidyVenV.InVersterking !== null
          && this.lead.financing.snnSubsidyVenV.InVersterking !== 'Ja');
      },
      showSubsidy17k30kQuestions() {
        return (
          this.lead.financing.snnSubsidyVenV.InVersterking !== null
          && this.lead.financing.snnSubsidyVenV.InVersterking !== 'Nee');
      }
    },
    methods: {
      touchAndValidate() {
        this.v$.ownership.$touch();
        return !this.v$.ownership.$invalid;
      },
      onSubmit() {
        if (this.touchAndValidate()) {
          FlowService.pushNext(this);
        }
      },
      onPrev() {
        FlowService.pushPrev(this);
      }
    }
  }
</script>

<style lang="scss">
</style>